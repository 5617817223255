import { faker } from '@faker-js/faker/locale/en'
import {
  DisplayRules,
  IActiveWholesalerServices,
  ICatalog,
  IOrderAccount,
  IOrderAccountData,
  IShopWholesaler,
  IUserField,
  IWholesalerGMS,
  IWholesalerOrderSystem,
  IWholesalerOrderSystemService,
  IWholesalerSupport,
  VehicleSource
} from './index'
import { MockFn, referenceDate } from '../../mock'
import { CountryCode } from '@carsys/enums/country-code'
import { CatalogStrategyType, CmsSystem, OrderSystem, OrderSystemService, TicketSystem } from '../enums'
import { mockAccount } from '../accounts/mocks'
import { IAccount } from '../accounts'

interface IMockOrderAccountData {
  Account?: Partial<IAccount>
  OrderAccount?: Partial<IOrderAccount>
  Wholesaler?: Partial<IWholesalerGMS>
  WholesalerOrderSystemServices?: Partial<IWholesalerOrderSystemService>[]
  Catalog?: Partial<ICatalog>
}

export const mockOrderAccountData: (extend?: IMockOrderAccountData) => IOrderAccountData = extend => {
  const account = mockAccount(extend && extend.Account) as any as IAccount
  const orderAccount = mockOrderAccount(extend && extend.OrderAccount) as any as IOrderAccount
  const wholesaler = mockWholesalerGMS(extend && extend.Wholesaler) as any as IWholesalerGMS
  const catalog = mockCatalog(extend && extend.Catalog) as any as ICatalog
  const services =
    (extend && extend.WholesalerOrderSystemServices) || (mockWholesalerOrderSystemServices() as any as IWholesalerOrderSystemService[])
  return {
    Account: account,
    OrderAccount: orderAccount,
    Wholesaler: wholesaler,
    Catalog: catalog,
    WholesalerOrderSystemServices: services
  } as IOrderAccountData
}

export const mockOrderAccount: MockFn<IOrderAccount> = (extend?) => ({
  AccountUUID: faker.datatype.uuid(),
  CreatedAt: faker.date.past(50, referenceDate).toISOString(),
  ShopID: faker.random.alphaNumeric(10),
  UUID: faker.datatype.uuid(),
  UpdatedAt: faker.date.past(50, referenceDate).toISOString(),
  Credentials: {
    UserId: faker.datatype.uuid(),
    Password: faker.internet.password(),
    ExternalID: faker.datatype.number().toString()
  },
  WholesalerUUID: faker.datatype.uuid(),
  DoNotShowOnList: false,
  DisplayRules: mockDisplayRules(),
  ...extend
})

export const mockDisplayRules: MockFn<DisplayRules> = (extend?) => ({
  ShowBandenNuTyresiFrame: false,
  RuleDisplayProductAvailabilityCallback: false,
  ShowPlusPartsBanner: false,
  HidePriceStockInCart: false,
  HideLocalStockLevel: false,
  HideTotalStockLevel: false,
  ShowUniversalPartsAndToolSpecialBanners: false,
  ShowEquipmentSpecialBanner: false,
  ShowHBaseVehicleSelector: true,
  ShowAvailabilityFilter: false,
  ShowEMDMVehicleSelector: false,
  ShowSortDropdown: false,
  OverwriteVehicleProfileOnHBaseVehicle: true,
  OverwriteVehicleProfileOnTecDocPlusVehicle: false,
  ShowRMAFunctions: false,
  UseProductGroupSearchViaAPI: false,
  HasCMS: false,
  HideOrderInclVATPrice: false,
  ...extend
})

export const mockWholesalerGMS: MockFn<IWholesalerGMS> = (extend?) => ({
  CreatedAt: faker.date.past(50, referenceDate).toISOString(),
  UpdatedAt: faker.date.past(50, referenceDate).toISOString(),
  UUID: faker.datatype.uuid(),
  HaynesProDistributorUUID: faker.datatype.uuid(),
  Name: faker.internet.userName(),
  Country: CountryCode.NL,
  LogoURL: faker.internet.avatar(),
  OrderSystem: OrderSystem.S1,
  IsActive: faker.datatype.boolean(),
  TermsAndConditionsURL: faker.internet.url(),
  SupportPhoneNumber: faker.phone.number(),
  SupportEmail: faker.internet.email(),
  CMSURL: faker.internet.url(),
  IFrameURL: faker.internet.url(),
  CMSSystem: CmsSystem.Raw,
  TicketSystem: TicketSystem.Zendesk,
  OrderSystemSettings: {},
  UserFields: [],
  HideLocalStock: faker.datatype.boolean(),
  HideTotalStock: faker.datatype.boolean(),
  OrderEmailAcknowledgementWholesalerEnabled: false,
  OrderEmailAcknowledgementWholesalerEmailAddress: '',
  AvailableForPortal: true,
  ...extend
})

export const mockWholesalerSupport: MockFn<IWholesalerSupport> = (extend?) => ({
  CreatedAt: faker.date.past(50, referenceDate).toISOString(),
  UpdatedAt: faker.date.past(50, referenceDate).toISOString(),
  UUID: faker.datatype.uuid(),
  HaynesProDistributorUUID: faker.datatype.uuid(),
  Name: faker.internet.userName(),
  Country: CountryCode.NL,
  LogoURL: faker.internet.avatar(),
  OrderSystem: OrderSystem.S1,
  IsActive: faker.datatype.boolean(),
  TermsAndConditionsURL: faker.internet.url(),
  SupportPhoneNumber: faker.phone.number(),
  SupportEmail: faker.internet.email(),
  CMSURL: faker.internet.url(),
  IFrameURL: faker.internet.url(),
  CMSSystem: CmsSystem.Raw,
  TicketURL: faker.internet.url(),
  TicketSystem: TicketSystem.Zendesk,
  OrderSystemSettings: {},
  TicketAPIKey: faker.internet.password(),
  TicketAgentEmail: faker.internet.email(),
  TicketBrandID: faker.random.word(),
  HideLocalStock: faker.datatype.boolean(),
  HideTotalStock: faker.datatype.boolean(),
  OrderEmailAcknowledgementWholesalerEnabled: false,
  OrderEmailAcknowledgementWholesalerEmailAddress: '',
  AvailableForPortal: true,
  ...extend
})

export const mockUserField: MockFn<IUserField> = (extend?) => ({
  Description: faker.random.words(),
  DisplayName: faker.random.word(),
  IsRequired: faker.datatype.boolean(),
  Disabled: faker.datatype.boolean(),
  Name: faker.random.word(),
  ...extend
})

export const mockShopWholesaler: MockFn<IShopWholesaler> = (extend?) => ({
  UUID: faker.datatype.uuid(),
  Name: faker.internet.userName(),
  LogoURL: faker.internet.avatar(),
  OrderSystem: OrderSystem.S1,
  IsLinked: faker.datatype.boolean(),
  Country: 'NL',
  UserFields: [mockUserField()],
  SupportEmail: faker.internet.email(),
  SupportPhoneNumber: faker.phone.number(),
  ...extend
})

export const mockCatalog: MockFn<ICatalog> = (extend?) => ({
  CarsysProductTreeUUID: 'some-tree-uuid',
  CatalogType: CatalogStrategyType.CarsysProductTree,
  VPOMDashboardCatalogUUIDS: [],
  UUID: faker.datatype.uuid(),
  WholesalerUUID: faker.datatype.uuid(),
  VehiclePrimaryVehicleSource: VehicleSource.CARSYS_HBASE,
  VPOMUseDevelopmentAPI: false,
  VPOMHidePDHForNonTyres: false,
  ...extend
})

export const mockWholesalerOrderSystemServices: MockFn<IWholesalerOrderSystemService[]> = () => [
  {
    Active: true,
    CountryCode: CountryCode.NL,
    OrderSystem: OrderSystem.S1,
    Config: '',
    Service: OrderSystemService.PriceStock,
    CreatedAt: faker.date.past(50, referenceDate).toISOString(),
    UpdatedAt: faker.date.past(50, referenceDate).toISOString()
  },
  {
    Active: true,
    CountryCode: CountryCode.NL,
    OrderSystem: OrderSystem.S1,
    Config: '',
    Service: OrderSystemService.Order,
    CreatedAt: faker.date.past(50, referenceDate).toISOString(),
    UpdatedAt: faker.date.past(50, referenceDate).toISOString()
  }
]

export const mockActiveWholesalerServices: MockFn<IActiveWholesalerServices> = (extend?) => ({
  Tyres: faker.datatype.boolean(),
  IFrame: faker.datatype.boolean(),
  Order: faker.datatype.boolean(),
  PriceStock: faker.datatype.boolean(),
  LicencePlate: faker.datatype.boolean(),
  RMA: faker.datatype.boolean(),
  Search: faker.datatype.boolean(),
  ...extend
})

export const mockWholesalerOrderSystem: MockFn<IWholesalerOrderSystem> = (extend?) => ({
  Name: faker.internet.userName(),
  Country: CountryCode.NL,
  Services: mockActiveWholesalerServices(),
  WholesalerFields: [],
  UserFields: [],
  ...extend
})
