import { Link as RouterLink, useRouteMatch } from 'react-router-dom'
import Link from '@mui/material/Link'
import { Typography } from '@mui/material'
import isFunction from 'lodash/fp/isFunction'

import { formatTitle } from '@cbgms/base/utils/helpers'

type TextFunction = (params?: any) => string

export interface ICrumbProps {
  path: string | string[]
  text?: string | TextFunction
  to?: string
  exact?: boolean // crumbs are flagged as 'finalNode' via path exact matches.
  forceAsFinalNode?: boolean // force to render as 'finalNode', some recursive routes don't place nice with exact matches
  'data-testid'?: string
}

export const Crumb: React.FC<ICrumbProps> = ({ path, text, to = undefined, exact = false, forceAsFinalNode, children, ...rest }) => {
  const p = useRouteMatch({ exact, path })

  if (!p) return null

  const crumb = isFunction(text) ? text(p.params) : text

  if (p.isExact || forceAsFinalNode)
    return (
      <Typography data-testid={rest['data-testid']} color='text.primary' variant='caption'>
        {crumb && formatTitle(crumb)}
        {children}
      </Typography>
    )

  return (
    <Link
      component={RouterLink}
      data-testid={rest['data-testid']}
      to={to || p.url}
      underline='hover'
      style={{ color: 'inherit' }}
      variant='caption'
    >
      {crumb && formatTitle(crumb)}
      {children}
    </Link>
  )
}
